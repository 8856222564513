import classes from "./ModalsGrid.module.css";
import PaymentCard from "../../image/PaymentCard.svg";
import styled from "styled-components";
import { useEffect, useState } from "react";
import $api from "../../http";
import BtnSpinner from "../btnSpinner/BtnSpinner";

const ModalDeposit = ({ theme, onCloseModal }) => {
  const [value, setValue] = useState(null);
  const [natification, setNatification] = useState("");
  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState();

  useEffect(() => {
    const fetchWithdrawalRequests = async () => {
      try {
        const responseUserData = await $api.get("/min_top_up_amount");
        setLimit(responseUserData.data);
      } catch (error) {
        console.error("Error fetching withdrawal requests", error);
      }
    };

    fetchWithdrawalRequests();
  }, []);

  const handleSaveBtn = async () => {
    if (!value?.length) {
      setNatification("Введите сумму!");
    } else if (+limit?.min_amount > +value) {
      setNatification(`Минимальная сумма пополнения ${+limit?.min_amount}!`);
    } else {
      try {
        setLoading(true);

        const responce = await $api.post(`/top_up_balance`, {
          amount: value,
        });

        if (responce?.statusText === "OK" || responce?.status === 200) {
          const paymentUrl = responce?.data?.payment_url;

          if (paymentUrl) {
            // window.open(paymentUrl, "_blank");
            window.location.href = paymentUrl;
          }
        }

        setNatification("Запрос на пополнение успешно отправлен!");
        setValue(null);
      } catch (error) {
        setNatification("Ошибка при отправке запроса.");
      } finally {
        setLoading(false);
      }

      setTimeout(() => {
        setNatification("");
        setValue(null);
        onCloseModal();
      }, 3000);
    }
  };

  return (
    <div className={classes.wrapper}>
      <img src={PaymentCard} alt="PaymentCard" />
      <Title theme={theme} className={classes.title}>
        Внести средства
        <br />
        на счет бота
      </Title>
      <Input
        type="number"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="100 $"
      />
      {loading ? (
        <Submit loading={loading}>
          <BtnSpinner />
        </Submit>
      ) : (
        <Submit onClick={handleSaveBtn} loading={loading}>
          Отправить запрос
        </Submit>
      )}

      {natification && (
        <Natification theme={theme} className={classes.natification}>
          {natification}
        </Natification>
      )}
    </div>
  );
};

export default ModalDeposit;

const Natification = styled.div`
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 8px;
  position: fixed;
  bottom: 5px;
  right: 20px;
  ${(props) =>
    props.theme === "light"
      ? "background: #000; color: #fff;"
      : "background: #fff; color: #000;"}
`;

const Title = styled.div`
  text-align: center;
  margin-top: 10px;
  color: ${(props) => (props.theme === "dark" ? "#fff" : "#000")};
`;

const Input = styled.input`
  padding: 10px;
  width: 90%;
  border-radius: 20px;
  border: none;
  background: #f1f1f1;
  margin-top: 20px;
  text-align: center;
  font-family: system-ui;
`;

const Submit = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  letter-spacing: -0.02em;
  text-align: center;
  width: 90%;
  padding: 9px;
  background: #beff8b;
  border-radius: 20px;
  margin-top: 15px;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 0px gray;
  transition: all 0.3s ease;
  opacity: ${(props) => (props.loading ? "0.7" : "1")};
  &:hover {
    cursor: ${(props) => (props.loading ? "default" : "pointer")};
    box-shadow: 1px 2px 20px gray;
  }
`;
