import classes from "./BtnSpinner.module.css";

const BtnSpinner = () => {
  return (
    <div className={classes.loading}>
      <div>
        <div className={classes.ldsSpinner}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default BtnSpinner;
