import classes from "./ModalsGrid.module.css";
import PaymentCard from "../../image/PaymentCard.svg";
import styled from "styled-components";
import { useState } from "react";
import $api from "../../http";

const ModalFromCash = ({ theme, onCloseModal }) => {
  const [value, setValue] = useState(null);
  const [valueAddress, setValueAddress] = useState(null);
  const [natification, setNatification] = useState("");

  const [typeAmount, setTypeAmount] = useState("TOP_UP");

  const clearData = () => {
    setValueAddress(null);
    setValue(null);
  };

  const handleBtn = async () => {
    if (typeAmount === 'ALL-MONEY'){
      if (!valueAddress?.length) {
        setNatification("Введите адрес кошелька!");
      }
      else{
        try{
          const { data } = await $api.post(`/withdrawal_request_all_amount`, {
            address: valueAddress,
          });
          setNatification("Менеджер с вами свяжется!" );
          setTimeout(() => {
            clearData();
          }, 1000);
          setTimeout(() => {
            onCloseModal();
          }, 3000);
          return;
        }
        catch(error){
          if (error?.response?.data?.error?.key === "not_enough_funds") {
            setNatification("Недостаточно средств на балансе!");
          } else if (
            error?.response?.data?.error?.key === "max_number_withdrawal_requests"
          ) {
            setNatification("Превышено кол-во выводов за последние сутки!");
          } else if (
            error?.response?.data?.detail[0]?.type === "greater_than_equal"
          ) {
            setNatification(
              `Минимальная сумма для вывода ${error?.response?.data?.detail[0]?.ctx?.ge} USDT!`
            );
          } else {
            setNatification("Что-то полшло не так! Попробуйте позже!");
          }
        }
      }
    }
    else if (!value?.length) {
      setNatification("Введите сумму!");
    } else if (!valueAddress?.length) {
      setNatification("Введите адрес кошелька!");
    } else {
      try {
        const { data } = await $api.post(`/withdrawal_request`, {
          amount: value,
          type: typeAmount,
          address: valueAddress,
        });

        console.log("data", data);
        setNatification(
          `${
            typeAmount === "TOP_UP"
              ? "Вам на почту отправлено подтверждение о выводе. Срок вывода тела может длиться до 14 дней!"
              : "Вам на почту отправлено подтверждение о выводе!"
          }`
        );
        setTimeout(() => {
          clearData();
        }, 1000);
        setTimeout(() => {
          onCloseModal();
        }, 3000);
      } catch (error) {
        if (error?.response?.data?.error?.key === "not_enough_funds") {
          setNatification("Недостаточно средств на балансе!");
        } else if (
          error?.response?.data?.error?.key === "max_number_withdrawal_requests"
        ) {
          setNatification(
            `${
              typeAmount === "TOP_UP"
                ? "Превышено кол-во выводов за последний месяц!"
                : "Превышено кол-во выводов за последние сутки!"
            }`
          );
        } else if (
          error?.response?.data?.detail[0]?.type === "greater_than_equal"
        ) {
          setNatification(
            `Минимальная сумма для вывода ${error?.response?.data?.detail[0]?.ctx?.ge} USDT!`
          );
        } else {
          setNatification("Что-то полшло не так! Попробуйте позже!");
        }
      }
    }

    setTimeout(() => {
      setNatification("");
    }, 3000);
  };
  return (
    <div className={classes.wrapper}>
      <img src={PaymentCard} alt="PaymentCard" />
      <Title theme={theme} className={classes.title}>
        Вывести средства
      </Title>
      {typeAmount !== 'ALL-MONEY' && 
      (<Input
        type="number"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="100 $"
      />
      )}
      <Input
        type="string"
        value={valueAddress}
        onChange={(e) => setValueAddress(e.target.value)}
        placeholder="Адрес кошелька для вывода"
      />
      <CheckBoxWrapper
        onClick={() => {
          setTypeAmount("TOP_UP");
        }}
        theme={theme}
        active={typeAmount === "TOP_UP"}
      >
        <div></div>
        <p>Основной баланс</p>
      </CheckBoxWrapper>
      <CheckBoxWrapper
        onClick={() => {
          setTypeAmount("ACCRUAL");
        }}
        theme={theme}
        active={typeAmount === "ACCRUAL"}
      >
        <div></div>
        <p>Процентный баланс</p>
      </CheckBoxWrapper>
      <CheckBoxWrapper
        onClick={() => {
          setTypeAmount("ALL-MONEY");
        }}
        theme={theme}
        active={typeAmount === "ALL-MONEY"}
      >
        <div></div>
        <p>Вывод всех средств</p>
      </CheckBoxWrapper>
      <Submit onClick={() => handleBtn()}>Отправить запрос</Submit>
      {natification && (
        <Natification theme={theme} className={classes.natification}>
          {natification}
        </Natification>
      )}
    </div>
  );
};

export default ModalFromCash;

const CheckBoxWrapper = styled.div`
  display: flex;
  gap: 8px;
  color: ${(props) => (props.theme === "dark" ? "#fff" : "#000")};
  margin-top: 18px;
  cursor: pointer;
  align-items: center;

  > div {
    height: 12px;
    width: 12px;
    border-radius: 2px;
    transition: all 0.3s ease;
    background: ${(props) => (props.active ? "#beff8b" : "")};
    border: 1px solid
      ${(props) => (props.theme === "dark" ? "#686868" : "#000")};
  }

  > p {
    margin: 0px;
    font-size: 13px;
  }
`;

const Natification = styled.div`
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 8px;
  position: fixed;
  bottom: 5px;
  right: 20px;
  ${(props) =>
    props.theme === "light"
      ? "background: #000; color: #fff;"
      : "background: #fff; color: #000;"}
`;

const Title = styled.div`
  text-align: center;
  margin-top: 10px;
  color: ${(props) => (props.theme === "dark" ? "#fff" : "#000")};
`;

const Input = styled.input`
  padding: 10px;
  width: 90%;
  border-radius: 20px;
  border: none;
  background: #f1f1f1;
  margin-top: 20px;
  text-align: center;
  font-family: system-ui;
`;

const Submit = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  letter-spacing: -0.02em;
  text-align: center;
  width: 90%;
  padding: 9px;
  background: #beff8b;
  border-radius: 20px;
  margin-top: 15px;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 0px gray;
  &:hover {
    cursor: pointer;
    box-shadow: 1px 2px 20px gray;
  }
`;
