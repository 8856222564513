import { useEffect, useState } from "react";
import $api from "../../http";
import styled from "styled-components";
import classes from "./ModalsGrid.module.css";

const ModalReferalInfo = ({ theme }) => {
  const [referalArr, setReferalArr] = useState([]);

  useEffect(() => {
    const fetchRefferalInfo = async () => {
      try {
        const responseRefrData = await $api.get("/get_refferal_info");
        setReferalArr(responseRefrData.data);
      } catch (error) {
        console.error("Ошибка при загрузке реферальной информации:", error);
      }
    };

    fetchRefferalInfo();
  }, []);
  const formatNumber = (number) => {
    const [integerPart, decimalPart] = number.toString().split(".");

    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return decimalPart
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
  };

  const chekLvl = (lvl) => {
    switch (lvl) {
      case 1:
        return "с первой";

      case 2:
        return "со второй";

      case 3:
        return "с третьей";

      default:
        return;
    }
  };

  return (
    <div className={classes.wrapperReferal}>
      <Title theme={theme}>Реферальная структура</Title>
      <LinesWrapper>
        {referalArr?.map((ref) => (
          <NumberLine theme={theme} key={ref.id}>
            <TitleLine>
              <Name>{`${ref.level}-я линия`}</Name>
              <Count>
                <div
                  style={{
                    fill: `${theme === "dark" ? "#fff" : "#000"}`,
                    marginBottom: "-7px",
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={theme === "dark" ? "#fff" : "#000"}
                  >
                    <circle
                      cx="11.8232"
                      cy="8"
                      r="4"
                      fill={theme === "dark" ? "#fff" : "#000"}
                    />
                    <circle
                      cx="16.8232"
                      cy="8"
                      r="3"
                      fill={theme === "dark" ? "#fff" : "#000"}
                    />
                    <circle
                      cx="6.82324"
                      cy="8"
                      r="3"
                      fill={theme === "dark" ? "#fff" : "#000"}
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.3916 17H19.7182C20.3098 17 20.7634 16.4901 20.6197 15.9162C20.2515 14.4458 19.2711 12 16.8232 12C15.9371 12 15.2433 12.3205 14.7012 12.7991C16.2089 13.7773 16.9885 15.4902 17.3916 17Z"
                      fill={theme === "dark" ? "#fff" : "#000"}
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.94534 12.7991C8.40325 12.3205 7.70945 12 6.82337 12C4.37545 12 3.39503 14.4458 3.02679 15.9162C2.88307 16.4901 3.33672 17 3.92834 17H6.25492C6.658 15.4902 7.43759 13.7773 8.94534 12.7991Z"
                      fill={theme === "dark" ? "#fff" : "#000"}
                    />
                    <path
                      d="M11.8232 13C15.5319 13 16.4897 16.301 16.7371 18.0061C16.8164 18.5526 16.3755 19 15.8232 19H7.82324C7.27096 19 6.83008 18.5526 6.90938 18.0061C7.15676 16.301 8.11459 13 11.8232 13Z"
                      fill={theme === "dark" ? "#fff" : "#000"}
                    />
                  </svg>
                </div>
                {`(${ref.num_refferals})`}
              </Count>
            </TitleLine>
            <PriceLine theme={theme}>
              <LineCounts>
                <Text>Общая сумма внесенных средств</Text>
                <Price theme={theme}>
                  {formatNumber(ref?.ref_main_balance)} $
                </Price>
              </LineCounts>
              <LineCounts>
                <Text>Общая сумма заработка линии в месяц</Text>
                <Price theme={theme}>
                  {formatNumber(ref?.ref_accrual_balance)} $
                </Price>
              </LineCounts>
              <LineCounts>
                <Text>
                  Заработок клиента в месяц {chekLvl(ref.level)} линии
                </Text>
                <Price theme={theme}>
                  {formatNumber(ref?.user_ref_profit)} $
                </Price>
              </LineCounts>
            </PriceLine>
          </NumberLine>
        ))}
      </LinesWrapper>
    </div>
  );
};

export default ModalReferalInfo;

const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 30.8px;
  letter-spacing: -0.02em;
  padding: 0px 0 0 25px;
  color: ${(props) => (props.theme === "dark" ? "#fff" : "#000")};
  text-wrap: nowrap;
  margin-right: auto;
`;

const NumberLine = styled.div`
  color: ${(props) => (props.theme === "dark" ? "#fff" : "#000")};
`;
const Name = styled.div``;
const Count = styled.div`
  display: flex;
  align-items: center;
  > img {
    margin-right: 6px;
  }
`;
const TitleLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 22px 0 14px 0;
`;
const PriceLine = styled.div`
  display: flex;
  align-items: space-between;
  justify-content: center;
  gap: 10px;

  border-bottom: 1px solid
    ${(props) => (props.theme === "dark" ? "#fff" : "#bcbcbc")};
`;

const Text = styled.div`
  color: #5f7385;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  letter-spacing: -0.02em;
`;
const Price = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 15.4px;
  letter-spacing: -0.02em;
  margin: 12px 0 20px 0;
  color: ${(props) => (props.theme === "dark" ? "#fff" : "#000")};
`;
const LinesWrapper = styled.div`
  margin: 0 25px 25px 25px;
`;
const LineCounts = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 32%;
  justify-content: space-between;
`;
