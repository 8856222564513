export function useTelegram() {
  const webApp = window.Telegram.WebApp;
  const user = webApp.initDataUnsafe?.user;
  //const lang = webApp.initDataUnsafe?.user?.language_code === 'ru' ? 'ru' : 'en'
  const lang = 'ru'
  return {
    webApp,
    user,
    lang
  };
}
