import classes from "./VerifyPage.module.css";
import GridBotImage from "../../image/GridBot.svg";
import Ellipse from "../../image/ellipse.svg";
import PageSpinner from "../../components/pageSpinner/PageSpinner";
import { useEffect, useState } from "react";
import $api from "../../http";

const VerifyPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [text, setText] = useState("Идёт проверка данных, ожидайте...");

  const [data, setData] = useState();

  const params = window.location?.search;

  useEffect(() => {
    const fetchWithdrawalRequests = async () => {
      if (params) {
        setIsLoading(true);
        try {
          const responseUserData = await $api.get(
            `/withdrawal_request/verify${params}`
          );
          setData(responseUserData.data);
          setText("Вывод успешно подтверждён!");
        } catch (error) {
          console.error("Error fetching withdrawal requests", error);
          setText("Эта ссылка не действительна!");
        } finally {
          setIsLoading(false);
          setTimeout(() => {
            // window.open("https://t.me/grid_app_bot-bot", "_blank");
            window.location.href = "https://t.me/grid_app_bot";
          }, 5000);
        }
      }
    };

    fetchWithdrawalRequests();
  }, [params]);

  console.log("data", data);
  return (
    <>
      {isLoading ? (
        <div className={classes.wrapper}>
          <PageSpinner />
        </div>
      ) : (
        <div className={classes.wrapper}>
          <div className={classes.headerImage + " wrapper"}>
            <img src={GridBotImage} alt="griddBot" className={classes.botImg} />
            <img src={Ellipse} alt="Ellipse" className={classes.ellipse} />
            <div className={classes.headerTextWrapper}>
              <div className={classes.headerDescr}>{text}</div>
              <div className={classes.headerInfo}>
                Сейчас произойдёт перенаправление в Grid-bot!
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyPage;
