import $api from '../../http';

export const updatePersonalInfo = (useCloudStorageResult, useTelegramResult, userInfoPayload) => async (dispatch) => {
    try {
        const { setItem, getItemAsync } = useCloudStorageResult;  
        const { webApp, lang } = useTelegramResult;
        const data = {
            first_name: userInfoPayload.newFirstName,
            last_name: userInfoPayload.newLastName,
            surname: userInfoPayload.newSurname,
            company: userInfoPayload.newCompany,
        }
        const response = await $api.post('/user', data);
        const userData = response.data;    
        if (userData.first_name === userInfoPayload.newFirstName && userData.last_name === userInfoPayload.newLastName && userInfoPayload.newSurname && userData.company === userInfoPayload.newCompany){
            setItem('userData', JSON.stringify(userData));
            console.log(userData);
        }
    }
    catch (error) {
        console.error('Error fetching user data:', error);
    } 
}